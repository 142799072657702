<template>
  <div class="container">
    <div style="height: 20px"></div>
    <div style="padding: 20px; display: flex; align-items: center">
      <span style="font-size: 14px; color: #606266">搜索医院：</span>
      <el-input placeholder="请输入医院名称" v-model="hospitalname" clearable="true" style="width: 280px" :disabled="isHospitalAccount">
        <el-button slot="append" icon="el-icon-search" @click="refreshList" :disabled="isHospitalAccount"></el-button>
      </el-input>

      <span style="margin-left: 20px;font-size: 14px; color: #606266;">搜索指导医生：</span>
      <el-input placeholder="请输入指导医生" v-model="zdys" clearable="true" style="width: 280px">
        <el-button slot="append" icon="el-icon-search" @click="refreshList"></el-button>
      </el-input>
      <span style="margin-left: 20px; font-size: 14px; color: #606266;">选择区域：</span>
      <el-cascader
          :disabled="isHospitalAccount"
          style="width: 280px"
          v-model="areaModel"
          :options="areaList"
          :props="{ checkStrictly: true }"
          @change="onAreaChange"
          clearable>
      </el-cascader>
    </div>
    <div style="padding: 20px; display: flex; align-items: center">
      <span style="font-size: 14px; color: #606266">时间范围：</span>
      <el-date-picker
          v-model="dateRange"
          type="daterange"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          value-format="yyyy-MM-dd HH:mm:ss"
          @change="onPickerDateChange">
      </el-date-picker>
    </div>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-card shadow="hover">
          <div class="card">
            <div class="card-icon">
              <i class="el-icon-s-order"></i>
            </div>
            <div style="
                flex: 1;
                display: flex;
                flex-direction: column;
                margin-left: 15px;
              ">
              <span class="num">{{ totalorderpeoplecount }}</span>
              <span class="description">有效测评(参与测评)人次</span>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="hover">
          <div class="card">
            <div class="card-icon-2">
              <i class="el-icon-s-order"></i>
            </div>
            <div style="
                flex: 1;
                display: flex;
                flex-direction: column;
                margin-left: 15px;
              ">
              <span class="num">{{ totalordercount }}</span>
              <span class="description">有效订单数</span>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="hover">
          <div class="card">
            <div class="card-icon-3">
              <i class="el-icon-s-order"></i>
            </div>
            <div style="
                flex: 1;
                display: flex;
                flex-direction: column;
                margin-left: 15px;
              ">
              <span class="num">￥{{ totalorderamount ? totalorderamount : "0.00" }}</span>
              <span class="description">有效订单总额</span>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <div style="height: 20px"></div>
    <el-table :data="tableData" border v-loading="loading" size="small" style="width: 100%" :row-style="{height:'56px'}">
      <el-table-column type="index" label="#" width="50"> </el-table-column>
      <el-table-column prop="ordercode" label="订单编号" min-width="180">
      </el-table-column>
      <el-table-column prop="hospitalname" label="医院名称" width="250">
      </el-table-column>
      <el-table-column prop="paynums" label="购买次数" width="150">
      </el-table-column>
      <el-table-column prop="orderamount" label="订单金额(￥)" width="150">
      </el-table-column>
      <el-table-column label="订单状态" width="150">
        <template slot-scope="scope">
          <span v-if="scope.row.orderstatus == 0">待支付</span>
          <span v-if="scope.row.orderstatus == 5">支付成功</span>
          <span v-if="scope.row.orderstatus == 2">支付失败</span>
          <span v-if="scope.row.orderstatus == 6">已使用</span>
        </template>
      </el-table-column>
      <el-table-column prop="createtime" label="下单时间" min-width="150">
      </el-table-column>
      <!-- 			<el-table-column fixed="right" label="操作" width="220">
        <template slot-scope="scope">
          <el-button @click="viewDetails(scope.row)" size="small">查看详情</el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <div class="footer">
      <el-pagination background @current-change="handleCurrentChange" @size-change="handleSizeChange"
                     :page-sizes="[10, 15, 30]" :page-size="pageSize" layout="total,sizes, prev, pager, next, jumper"
                     :total="totalData">
      </el-pagination>
    </div>
    <el-dialog append-to-body title="订单详情" :visible.sync="viewDetailDialog" width="700px">
      <OrderHistoryDetails :order-data="viewDetailObj"></OrderHistoryDetails>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api";
import OrderHistoryDetails from "../../components/OrderHistoryDetails";
import axios from "axios";

export default {
  name: "TongJi",
  components: {
    OrderHistoryDetails
  },
  data() {
    return {
      loading: false,
      pageIndex: 1,
      pageSize: 10,
      totalData: 0,
      tableData: [],
      viewDetailDialog: false,
      viewDetailObj: null,
      loginData: null,
      timeSelect: "全部",
      totalorderamount: "0",
      totalordercount: "0",
      totalorderpeoplecount: "0",
      areaList: [],
      areaModel: ["","",""],
      dateRange: ["",""],
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            end.setHours(23);
            end.setMinutes(59);
            end.setSeconds(59);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '昨天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '本周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '本月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '半年',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '本年',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      hospitalname: "",
      zdys: "",
      isHospitalAccount: false,
    };
  },
  created() {
    this.loginData = JSON.parse(localStorage.getItem("logindata"));
    this.isHospitalAccount = localStorage.getItem("ishospital") == 1;
    this.initList();
    this.initTotalData();
    this.initAreaList();
  },
  methods: {
    refreshList(){
      this.initList();
      this.initTotalData();
    },
    initList() {
      this.loading = true;
      let params = {
        ordercode: this.ordercode,
        orderstatus: 5,
        categorycode: this.loginData.categorycode,
        userlevel: this.loginData.userlevel,
        page: this.pageIndex,
        pageSize: this.pageSize,
        zdys: this.zdys,
        categoryfullname: this.hospitalname,
      };
      if (this.dateRange) {
        params.starttime = this.dateRange[0];
        params.endtime = this.dateRange[1];
      }
      if (this.areaModel) {
        params.provinceid = this.areaModel[0];
        params.cityid = this.areaModel[1];
        params.districtid = this.areaModel[2];
      }
      api
          .getMUserOrderPageList(params)
          .then((res) => {
            if (res.data.success === "1") {
              this.totalData = res.data.data.totalRecords;
              this.tableData = res.data.data.list;
            } else {
              this.$message({
                showClose: true,
                message: res.data.errorMsg,
                type: "warning",
              });
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.$message({
              showClose: true,
              message: "请检查网络连接",
              type: "warning",
            });
          });
    },
    initAreaList() {
      let url = "https://restapi.amap.com/v3/config/district?key=4f3b15d4e9ec39a20d3fb459c65ecf17&subdistrict=3";
      this.loading = true;
      axios.get(url).then((res) => {
        console.log(res);
        let list = [];
        for (let pIndex in res.data.districts[0].districts) {
          let pItem = res.data.districts[0].districts[pIndex];
          let value = {};
          value.provinceid = pItem.adcode;
          value.label = pItem.name;
          value.value = pItem.adcode;
          if (pItem.districts && pItem.districts.length > 0) {
            value.children = [];
          }
          for (let cIndex in pItem.districts) {
            let cItem = pItem.districts[cIndex];
            let cValue = {
              cityid: cItem.adcode,
              label: cItem.name,
              value: cItem.adcode,
            };
            if (cItem.districts && cItem.districts.length > 0) {
              cValue.children = [];
            }
            for (let dIndex in cItem.districts) {
              let dItem = cItem.districts[dIndex];
              cValue.children.push({
                districtid: dItem.adcode,
                label: dItem.name,
                value: dItem.adcode,
              });
            }
            value.children.push(cValue);
          }
          list.push(value);
        }
        this.areaList = list;
        this.loading = false;
      });
    },
    onPickerDateChange(e){
      console.log(e);
      this.dateRange = e;
      this.refreshList();
    },
    onAreaChange(value) {
      console.log(value);
      this.areaModel = value;
      this.refreshList();
    },
    viewDetails(row) {
      console.log(row);
      this.viewDetailObj = row;
      this.viewDetailDialog = true;
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.initList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.initList();
    },
    initTotalData() {
      let params = {
        categorycode: this.loginData.categorycode,
        userlevel: this.loginData.userlevel,
        page: this.pageIndex,
        pageSize: this.pageSize,
        orderstatus: "5",
        zdys: this.zdys,
        categoryfullname: this.hospitalname,
      };
      if (this.dateRange) {
        params.starttime = this.dateRange[0];
        params.endtime = this.dateRange[1];
      }
      if (this.areaModel) {
        params.provinceid = this.areaModel[0];
        params.cityid = this.areaModel[1];
        params.districtid = this.areaModel[2];
      }
      api
          .getMUserOderData(params)
          .then((res) => {
            if (res.data.success === "1") {
              this.totalorderamount = res.data.data.totalorderamount;
              this.totalordercount = res.data.data.totalordercount;
              this.totalorderpeoplecount = res.data.data.totalorderpeoplecount;
            } else {
              this.$message({
                showClose: true,
                message: res.data.errorMsg,
                type: "warning",
              });
            }
          })
          .catch(() => {
            this.$message({
              showClose: true,
              message: "请检查网络连接",
              type: "warning",
            });
          });
    },
  },
};
</script>

<style scoped>
.container {
  padding: 20px;
}

.card {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card-icon {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #4d7cfe;
  color: white;
  text-align: center;
  line-height: 55px;
  border: 10px solid #e4ecff;
}

.card-icon-2 {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #ffab2b;
  color: white;
  text-align: center;
  line-height: 55px;
  border: 10px solid #fff3e0;
}

.card-icon-3 {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #b37feb;
  color: white;
  text-align: center;
  line-height: 55px;
  border: 10px solid #f1e4ff;
}

i {
  font-size: 20px;
}

.num {
  font-weight: bold;
  font-size: 20px;
  color: #303133;
}

.description {
  margin-top: 10px;
  font-size: 14px;
  color: #606266;
}

.footer {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
}
</style>
